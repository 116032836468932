export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "10%",
    order: 0,
    hidable: true,
    hidden: false,
  },
  { text: "Project Name", value: "name", order: 1, hidable: false, hidden: false },
  { text: "Phase Name", value: "phaseName", order: 1, hidable: false, hidden: false },
  {
    text: "Relative Weight",
    value: "relativeWeight",
    order: 2,
    hidable: true,
    hidden: false,
  },
  {
    text: "Percentage",
    value: "percentage",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Monthly Hours",
    value: "monthlyHours",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Daily Hours",
    value: "dailyHours",
    order: 6,
    hidable: true,
    hidden: false,
  },
];
