<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Monthly Plans"
      subtitle="Browse and manage your monthly plans!"
      :badge="total"
    />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col cols="12" sm="12">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <v-menu
            v-model="dateMenuFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="text-capitalize" color="white" height="32px" v-bind="attrs" v-on="on">
                <i class="fad fa-filter me-2 fs-12px"></i>
                {{ options.Date ? `Date: ${options.Date}` : "Select Date From" }}
              </v-btn>
            </template>
            <v-date-picker
              v-model="options.Date"
              @input="dateMenuFrom = false"
              show-current
              show-week
              type="month"
            ></v-date-picker>
          </v-menu>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :server-items-length="total"
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 table-sticky-header-exclude-footer"
      show-expand
      :expanded.sync="expanded"
      item-key="id"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <dater :date="item.date" dateonly></dater>
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
      </template>
      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <div style="background: #eceff1" class="pa-4 inset-shadow">
            <div class="font-weight-bold d-flex align-center mb-1" v-if="item.projects.length">
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                :loading="selectedId == item.id"
                :disabled="selectedForApplyId == item.id"
                @click="saveConfirmed(item)"
                class="mr-2"
                >Save</v-btn
              >
              <v-btn
                small
                color="error"
                :disabled="selectedId == item.id"
                :loading="selectedForApplyId == item.id"
                @click="apply(item)"
                >{{ item.isApplied ? "Re-Apply" : "Apply" }}</v-btn
              >
            </div>
            <v-data-table
              dense
              :headers="monthlyPlanHeader"
              :items="item.projects"
              hide-default-footer
              disable-pagination
              class="elevation-2"
            >
              <template v-slot:no-data>
                <img width="200" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Projects Available!</p>
              </template>
              <!-- <template v-slot:[`item.relativeWeight`]="date">
              <div
                contenteditable
                class="px-2 py-1"
                @blur="updatePlanRow($event, item, date.index, date.item)"
              >
                {{ date.item.relativeWeight }}
              </div>
            </template> -->
              <template v-slot:[`item.relativeWeight`]="date">
                <div
                  contenteditable
                  class="px-2 py-1"
                  @blur="updatePlanRow($event, item, date.index, date.item)"
                >
                  {{ date.item.relativeWeight || 0 }}
                </div>
              </template>
              <template v-slot:[`item.percentage`]="date">
                <div>
                  {{ date.item.percentage | percentage }}
                </div>
              </template>
              <template v-slot:[`item.monthlyHours`]="date">
                <div>
                  {{ date.item.monthlyHours | digit(2) }}
                </div>
              </template>
              <template v-slot:[`item.dailyHours`]="date">
                <div>
                  {{ date.item.dailyHours | digit(2) }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ index }">
                <v-btn color="red" icon elevation="0" class="ml-2" @click.stop="del(item, index)">
                  <i class="fal fa-trash-alt"></i>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ApiService from "../services/monthlyPlans-service.js";
import monthlyPlanHeader from "../config/tables/monthlyPlan.header";
import plans from "../config/tables/plans.header";
import moment from "moment";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    FilterManager,
  },
  data() {
    return {
      selectedId: null,
      selectedForApplyId: null,
      dateMenuFrom: false,
      storageKey: "MonthlyPlans",
      paramId: null,
      selectedFilters: [],
      entities: [],
      selected: {},
      expanded: [],
      total: 0,
      search: "",
      valid: false,
      monthlyPlansTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["firstName"],
        sortDesc: [true],
        UserId: [],
        Date: moment().format("YYYY-MM"),
      },
      loadingStates: {
        table: false,
      },
      headers: plans,
      defaultHeaders: [],
      monthlyPlanHeader,
      hasChange: false,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    convertDateToObject(date) {
      if (date) {
        const [year, month] = date.split("-");
        return { year: +year, month: +month };
      }
      return date;
    },
    SetEntries(items) {
      items = items.map((elm) => {
        const newProjects = [];
        elm.projects.forEach((project) => {
          project = this.cloneDeep(project);
          project.projectPhases.forEach((phase) => {
            project.projectPhaseId = phase.id;
            project.phaseName = phase.name;
            const plan = elm.monthlyPlans.find(
              (plan) => plan.projectPhase && plan.projectPhase.id == project.projectPhaseId
            );
            if (plan) {
              delete plan.duration;
              delete plan.project;
              delete plan.projectPhase;
              delete plan.id;
              Object.assign(project, plan);
            }
            newProjects.push(project);
          });
        });
        elm.projects = newProjects;
        return elm;
      });
      this.entities = items;
    },
    getData() {
      this.$backToTop();
      this.loadingStates.table = true;
      const dataToSend = this.cloneDeep(this.options);
      Object.assign(dataToSend, this.convertDateToObject(dataToSend.Date));
      delete dataToSend.Date;
      ApiService.query(this.$clean(dataToSend, true))
        .then((resp) => {
          this.SetEntries(resp.data.items);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    updatePlanRow(e, item, planIndex, plan) {
      const index = this.entities.findIndex((elm) => elm.id == item.id);
      let relativeWeight = e.target.innerText.match(/\d+/); // "3"
      relativeWeight = relativeWeight ? parseInt(relativeWeight[0]) : 0;
      e.target.innerText = relativeWeight;
      plan.relativeWeight = relativeWeight;
      this.$set(this.entities[index].projects, planIndex, plan);
      this.updateAll(index, item.workLoad, item.workableHours);
    },
    updateAll(index, workLoad = 0, workableHours) {
      workLoad = parseInt(workLoad);
      const total = this.entities[index].projects.reduce(
        (pre, cur) => pre + (cur.relativeWeight || 0),
        0
      );
      const plans = this.entities[index].projects.map((plan) => {
        plan.percentage = ((plan.relativeWeight || 0) / total) * 100;
        plan.monthlyHours = (plan.percentage / 100) * workableHours;
        const totalMonthly = this.entities[index].projects.reduce(
          (pre, cur) => pre + (cur.monthlyHours || 0),
          0
        );
        plan.dailyHours = (plan.monthlyHours / totalMonthly) * workLoad;
        return plan;
      });
      this.$set(this.entities[index], "projects", plans);
    },
    del(item, index) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this monthly plan?`,
        title: `Delete Monthly Plan?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              //empty the value and then
              Object.assign(item.projects[index], {
                relativeWeight: 0,
                percentage: 0,
                monthlyHours: 0,
                dailyHours: 0,
              });
              return this.saveConfirmed(item);
            },
          },
        },
      });
    },
    apply(item) {
      this.$dialog
        .warning({
          text: `Are you sure you want to apply these monthly plans?`,
          title: `Apply Monthly Plans?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                this.selectedForApplyId = item.id;
                const dataToSend = this.cloneDeep(this.options);
                return ApiService.apply(item.id, this.convertDateToObject(dataToSend.Date))
                  .then(() => {
                    this.$dialog.notify.success("Plans Applied", {
                      position: "top-right",
                      timeout: 3000,
                    });
                    item.isApplied = true;
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  })
                  .finally(() => {
                    this.selectedForApplyId = null;
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    saveConfirmed(item) {
      const dateToSend = this.cloneDeep(item);
      this.selectedId = dateToSend.id;
      dateToSend.duration = this.convertDateToObject(this.options.Date);
      dateToSend.monthlyPlans = dateToSend.projects.map((elm) => {
        this.$log(elm);
        elm.projectId = elm.id;
        delete elm.projectPhases;
        delete elm.id;
        return elm;
      });
      delete dateToSend.projects;
      return ApiService.update(dateToSend)
        .then(() => {
          this.$dialog.notify.success("Item updated successfully", {
            position: "top-right",
            timeout: 3000,
          });
          item.isApplied = false;
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => {
          this.selectedId = null;
        });
    },
    getDataDebounced() {
      if (this.monthlyPlansTimerId == null) {
        this.monthlyPlansTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.monthlyPlansTimerId);

      // delay new call 400ms
      this.monthlyPlansTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
};
</script>
